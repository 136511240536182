<template>
  <div>
    <v-dialog
      v-model="activo"
      :max-width="detalles.filter(element => element.editar == 1).length > 0 ? '1100' : '800'"
    >
      <v-card
        flat
        :loading="load"
        :disabled="load"
      >
        <v-card-title style="word-break: normal;" class="text-center">
          <v-toolbar
            flat
            class="text-center"
          >
            <v-icon left>far fa-chart-bar</v-icon> Históricos Cumplimiento para {{ nombre }}
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="activo = false"
            >
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12">
              <v-card
                outlined
              >
                <v-card-text>
                  <v-row :no-gutters="$vuetify.breakpoint.xs">
                    <v-col cols="12">
                      <v-data-table
                        class="cebra elevation-2 mt-2"
                        :headers="headers"
                        :items="detalles"
                        :loading="load"
                        dense
                        :search="search"
                        item-key="fecha_desde"
                      >
                        <!-- Buscador en el datatable -->
                        <template v-slot:top>
                          <v-row class="d-flex justify-end pa-2" no-gutters>
                            <v-col cols="6" sm="9" md="9">
                              <v-btn
                                color="success"
                                small
                                :disabled="detalles.filter(element => element.editar == 1).length > 0"
                                @click="detalles.push({
                                  editar: 1,
                                  fecha_desde: moment(new Date()).format('DD/MM/YYYY'),
                                  fecha_hasta: null,
                                  grupo_id: null,
                                  grupo_nombre: '',
                                  id: 0,
                                  porcentaje: 0
                                }); fechaDesde = moment(new Date()).format('DD/MM/YYYY'); fechaHasta = null"
                              >
                                <v-icon left>fas fa-plus-circle</v-icon> Nuevo registro
                              </v-btn>
                            </v-col>
                            <v-col cols="6" sm="3" md="3">
                              <SearchDataTable
                                v-model="search"
                              />
                            </v-col>
                          </v-row>
                        </template>
                        <!-- Sin datos -->
                        <template v-slot:no-data>
                          <v-alert
                            class="mx-auto mt-4"
                            max-width="400"
                            type="warning"
                            border="left"
                            dense
                            text
                          >
                            Sin Grupos para mostrar.
                          </v-alert>
                        </template>
                        <!-- Porcentaje -->
                        <template v-slot:[`item.porcentaje`]="{ item }">
                          {{ item.porcentaje }}%
                        </template>
                        <!-- Fecha Desde -->
                        <template v-slot:[`item.fecha_desde`]="{ item }">
                          <div v-if="item.editar == 0">{{ format_date(item.fecha_desde) }}</div>
                          <div v-else>
                            <FechaPicker
                              v-model="fechaDesde"
                              :hideDetails="true"
                              :clearable="true"
                            />
                          </div>
                        </template>
                        <!-- Fecha Hasta -->
                        <template v-slot:[`item.fecha_hasta`]="{ item }">
                          <div v-if="item.editar == 0">{{ format_date(item.fecha_hasta) }}</div>
                          <div v-else>
                            <FechaPicker
                              v-model="fechaHasta"
                              :hideDetails="true"
                              :clearable="true"
                            />
                          </div>
                        </template>
                        <!-- Grupo -->
                        <template v-slot:[`item.grupo_nombre`]="{ item }">
                          <div v-if="item.editar == 0">{{ item.grupo_nombre }}</div>
                          <div v-else>
                            <v-autocomplete
                              v-model="item.grupo_id"
                              label="Grupo"
                              item-text="grupo_nom"
                              item-value="grupo_id"
                              :items="grupos"
                              clearable
                              outlined
                              dense
                              hide-details
                              @change="setGrupo(item)"
                            ></v-autocomplete>
                          </div>
                        </template>
                        <!-- Acciones -->
                        <template v-slot:[`item.acciones`]="{ item }">
                          <v-tooltip bottom v-if="item.editar == 0">
                            <template v-slot:activator="{ on }">
                              <v-btn small icon color="info" @click="editarGrupo(item)" v-on="on" :disabled="detalles.filter(element => element.editar == 1).length > 0">
                                <v-icon small>far fa-edit</v-icon>
                              </v-btn>
                            </template>
                            <span>Editar</span>
                          </v-tooltip>
                          <v-tooltip bottom v-if="item.editar == 1">
                            <template v-slot:activator="{ on }">
                              <v-btn small icon color="success" @click="confirmarGrupo(item)" v-on="on">
                                <v-icon small>far fa-check-circle</v-icon>
                              </v-btn>
                            </template>
                            <span>Confirmar</span>
                          </v-tooltip>
                          <v-tooltip bottom v-if="item.editar == 0">
                            <template v-slot:activator="{ on }">
                              <v-btn small icon color="error" @click="quitarGrupo(item)" v-on="on" :disabled="detalles.filter(element => element.editar == 1).length > 0">
                                <v-icon small>far fa-times-circle</v-icon>
                              </v-btn>
                            </template>
                            <span>Quitar</span>
                          </v-tooltip>
                          <v-tooltip bottom v-if="item.editar == 1">
                            <template v-slot:activator="{ on }">
                              <v-btn small icon color="warning" @click="deshacer(item)" v-on="on">
                                <v-icon small>fas fa-undo</v-icon>
                              </v-btn>
                            </template>
                            <span>Cancelar</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            color="warning"
            @click="activo = false"
            :disabled="detalles.filter(element => element.editar == 1).length > 0"
          >
            <v-icon left>fas fa-arrow-left</v-icon> Volver
          </v-btn>
          <v-btn
            color="success"
            @click="controlPeriodos()"
            :disabled="detalles.filter(element => element.editar == 1).length > 0"
          >
            <v-icon left>far fa-check-circle</v-icon> Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Modal Confirmar APLICAR GRUPO -->
    <ModalConfirmar 
      :activoDialog="dialogConf"
      :texto="`¿Está seguro de guardar los cambios realizados sobre los Periodos para ${nombre}?`"
      @action="setGrupoComisiones()"
      @setearModalConfAccion="setAsociar"
    />
  </div>
</template>

<script>
import SearchDataTable from '../util/SearchDataTable.vue'
import { format_date, cadenaVacia, objetoNoVacio, roundNumber, order_list_by_date2 } from '../../util/utils'
import FechaPicker from '../util/FechaPicker.vue'
import moment from 'moment'
import momentTz from 'moment-timezone'
import ModalConfirmar from '../util/ModalConfirmar.vue'
export default {
  props: {
    datos: {
      type: Object,
      default(){
        return {}
      }
    },
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearPeriodosFP', value)
      }
    },
    fp_cond_id: {
      get(){
        return this.datos.fp_cond_id
      }
    },
    nombre: {
      get(){
        return this.datos.nombre
      }
    },
    grupos: {
      get(){
        return this.datos.grupos
      }
    }
  },
  components: {
    SearchDataTable,
    FechaPicker,
    ModalConfirmar
  },
  data() {
    return {
      format_date: format_date,
      moment: moment,
      load: false,
      headers: [
        { text: 'F. Desde', align: 'center', value: 'fecha_desde' },
        { text: 'F. Hasta', align: 'center', value: 'fecha_hasta' },
        { text: 'Grupo', align: 'center', value: 'grupo_nombre' },
        { text: 'Porcentaje', align: 'center', value: 'porcentaje' },
        { text: 'Acciones', align: 'center', value: 'acciones' },
      ],
      detalles: [],
      search: '',
      itemActual: {},
      dialogConf: false,
      fechaDesde: null,
      fechaHasta: null
    }
  },
  methods: {
    async initModal(){
      this.load = true
      let initPeticion = await this.$store.dispatch('financiaciones/get_periodos_grupo_fp', {fp_cond_id: this.fp_cond_id})
      this.load = false
      if (initPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.msj,
          color: 'error'
        })
        return
      }
      // ordeno descendiente
      order_list_by_date2(initPeticion.grupos, 'fecha_desde', 'asc')
      // paso al formato que necesito
      // for (let id in initPeticion.grupos){
      //   initPeticion.grupos[id].fecha_desde = momentTz.tz(initPeticion.grupos[id].fecha_desde + ' 12:00:00', 'DD/MM/YYYY HH:mm:ss', 'UTC').format()
      //   initPeticion.grupos[id].fecha_hasta = cadenaVacia(initPeticion.grupos[id].fecha_hasta) ? null : momentTz.tz(initPeticion.grupos[id].fecha_hasta + ' 12:00:00', 'DD/MM/YYYY HH:mm:ss', 'UTC').format()
      // }
      this.detalles = initPeticion.grupos
    },
    clearModal(){
      this.detalles = []
      this.search = ''
      this.itemActual = {}
      this.fechaDesde = null
      this.fechaHasta = null
    },
    editarGrupo(item){
      this.itemActual = JSON.stringify(item)
      let pos = this.detalles.indexOf(item)
      this.fechaDesde = moment(this.detalles[pos].fecha_desde).format('DD/MM/YYYY')
      this.fechaHasta = (cadenaVacia(this.detalles[pos].fecha_hasta) || this.detalles[pos].fecha_hasta.toUpperCase() == 'INVALID DATE') ? null : moment(this.detalles[pos].fecha_hasta).format('DD/MM/YYYY')
      this.detalles[pos].editar = 1
    },
    confirmarGrupo(item){
      this.itemActual = {}
      let pos = this.detalles.indexOf(item)
      let fecha_desde = (cadenaVacia(this.fechaDesde) || this.fechaDesde.toUpperCase() == 'INVALID DATE') ? null : this.fechaDesde.toString()
      let fecha_hasta = (cadenaVacia(this.fechaHasta) || this.fechaHasta.toUpperCase() == 'INVALID DATE') ? null : this.fechaHasta.toString()
      if (fecha_desde == null || fecha_desde.length != 10 || !moment(fecha_desde, 'DD/MM/YYYY').isValid()){
        this.$store.dispatch('show_snackbar', {
          text: 'Formato de Fecha inválido para Fecha Desde.',
          color: 'warning'
        })
        this.fechaDesde = null
        return
      }
      if (fecha_hasta != null && (fecha_hasta.length != 10 || !moment(fecha_hasta, 'DD/MM/YYYY').isValid())){
        this.$store.dispatch('show_snackbar', {
          text: 'Formato de Fecha inválido para Fecha Hasta.',
          color: 'warning'
        })
        this.fechaHasta = ''
        return
      }
      if (cadenaVacia(item.grupo_id)){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar el Grupo en la fila que se encuentra editando.',
          color: 'warning'
        })
        item.grupo_nom = ''
        return
      }
      this.detalles[pos].fecha_desde = momentTz.tz(fecha_desde + ' 12:00:00', 'DD/MM/YYYY HH:mm:ss', 'UTC').format()
      this.detalles[pos].fecha_hasta = fecha_hasta == null ? null : momentTz.tz(fecha_hasta + ' 12:00:00', 'DD/MM/YYYY HH:mm:ss', 'UTC').format()
      this.detalles[pos].editar = 0
      this.fechaDesde = null
      this.fechaHasta = null
      // reordeno
      order_list_by_date2(this.detalles, 'fecha_desde', 'asc')
    },
    quitarGrupo(item){
      this.itemActual = {}
      this.fechaDesde = null
      this.fechaHasta = null
      let pos = this.detalles.indexOf(item)
      this.detalles.splice(pos, 1)
      order_list_by_date2(this.detalles, 'fecha_desde', 'asc')
    },
    deshacer(item){
      if (item.id == 0){
        this.quitarGrupo(item)
        return
      }
      let pos = this.detalles.indexOf(item)
      let itemLocal = JSON.parse(this.itemActual)
      if (!objetoNoVacio(itemLocal)){
        this.$store.dispatch('show_snackbar', {
          text: 'No se pudo completar la acción. Revise las fechas nuevamente.',
          color: 'error'
        })
        this.detalles[pos].fecha_desde = momentTz.tz(moment(new Date()).format('DD/MM/YYYY') + ' 12:00:00', 'DD/MM/YYYY HH:mm:ss', 'UTC').format()
        this.detalles[pos].fecha_hasta = momentTz.tz(moment(new Date()).format('DD/MM/YYYY') + ' 12:00:00', 'DD/MM/YYYY HH:mm:ss', 'UTC').format()
        this.detalles[pos].editar = 0
        return
      }

      this.detalles[pos].fecha_desde = itemLocal.fecha_desde
      this.detalles[pos].fecha_hasta = itemLocal.fecha_hasta
      this.detalles[pos].grupo_id = itemLocal.grupo_id
      this.detalles[pos].grupo_nombre = itemLocal.grupo_nombre
      this.detalles[pos].porcentaje = itemLocal.porcentaje
      this.itemActual = {}
      this.detalles[pos].editar = 0
      this.fechaDesde = null
      this.fechaHasta = null
      order_list_by_date2(this.detalles, 'fecha_desde', 'asc')
    },
    setGrupo(item){
      if (cadenaVacia(item.grupo_id)){
        item.grupo_nombre = ''
        return 
      }
      item.grupo_nombre = this.grupos.filter(element => element.grupo_id == item.grupo_id)[0].grupo_nom
      item.porcentaje = roundNumber(this.grupos.filter(element => element.grupo_id == item.grupo_id)[0].porcentaje * 100, 2)
    },
    ordenarGrupos(){
      // this.detalles = this.detalles.map(element => ({
      //   editar: element.editar,
      //   fecha_desde: moment(element.fecha_desde).format('DD/MM/YYYY'),
      //   fecha_hasta: element.fecha_hasta,
      //   grupo_id: element.grupo_id,
      //   grupo_nombre: element.grupo_nombre,
      //   id: element.id,
      //   porcentaje: element.porcentaje
      // }))
      // order_list_by_date2(this.detalles, 'fecha_desde', 'asc')
      // this.detalles = this.detalles.map(element => ({
      //   editar: element.editar,
      //   fecha_desde: momentTz.tz(element.fecha_desde + ' 12:00:00', 'DD/MM/YYYY HH:mm:ss', 'UTC').format(),
      //   fecha_hasta: element.fecha_hasta,
      //   grupo_id: element.grupo_id,
      //   grupo_nombre: element.grupo_nombre,
      //   id: element.id,
      //   porcentaje: element.porcentaje
      // }))
    },
    setAsociar(value){
      this.dialogConf = value
    },
    controlPeriodos(){
      // ordeno una vez más por las chapas
      order_list_by_date2(this.detalles, 'fecha_desde', 'asc')
      // no tiene que haber dos o más fechas hasta vacias o con invalid date
      let hastaVacios = this.detalles.filter(element => cadenaVacia(element.fecha_hasta) || element.fecha_hasta.toUpperCase() == 'INVALID DATE' || !moment(element.fecha_hasta).isValid())
      // no tiene que haber ningún registro con fecha desde vacio
      let desdeVacios = this.detalles.filter(element => cadenaVacia(element.fecha_desde) || element.fecha_desde.toUpperCase() == 'INVALID DATE' || !moment(element.fecha_desde).isValid())
      if (hastaVacios.length == 0 || hastaVacios.length > 1 || desdeVacios.length > 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Se detectaron Periodos Inconsistentes. Revisar.',
          color: 'warning'
        })
        return
      }
      // no tengo que tener fechas desde/hasta repetidas
      for (let id in this.detalles){
        let repDesde = this.detalles.filter(element => moment(element.fecha_desde).format('DD/MM/YYYY') == moment(this.detalles[id].fecha_desde).format('DD/MM/YYYY'))
        let repHasta = this.detalles.filter(element => !cadenaVacia(element.fecha_hasta) && element.fecha_desde.toUpperCase() != 'INVALID DATE' && moment(element.fecha_hasta).format('DD/MM/YYYY') == moment(this.detalles[id].fecha_hasta).format('DD/MM/YYYY'))
        if (repDesde.length == 0 || repDesde.length > 1 || repHasta.length > 1){
          this.$store.dispatch('show_snackbar', {
            text: 'Se detectaron Fechas Desde/Hasta repetidas. Revisar.',
            color: 'warning'
          })
          return
        }
      }
      // todos los periodos tienen grupo?
      let gruposVacios = this.detalles.filter(element => cadenaVacia(element.grupo_id))
      if (gruposVacios.length > 0){
        this.$store.dispatch('show_snackbar', {
          text: 'No se pueden haber Periodos con Grupos sin definir. Revisar.',
          color: 'warning'
        })
        return
      }
      // control de la consistencia de los periodos, empiezo con la fecha desde más chica que debería estar en la última posición
      let fecha_desde = this.detalles[this.detalles.length - 1].fecha_desde
      let fecha_hasta = this.detalles[this.detalles.length - 1].fecha_hasta
      let grupo_id = this.detalles[this.detalles.length - 1].grupo_id
      let porcentaje = this.detalles[this.detalles.length - 1].porcentaje
      let otrosMenores = this.detalles.filter(element => moment(element.fecha_desde).toDate() < moment(fecha_desde).toDate())
      if (otrosMenores.length > 0){
        this.$store.dispatch('show_snackbar', {
          text: 'No se determinar la fecha mínima.',
          color: 'warning'
        })
        return
      }
      if (this.detalles.length > 1){
        for (let id = this.detalles.length - 2; id >= 0; id--){
          if (moment(this.detalles[id].fecha_desde).format('DD/MM/YYYY') != moment(fecha_hasta).add(1, 'day').format('DD/MM/YYYY')){
            this.$store.dispatch('show_snackbar', {
              text: 'No se dividió correctamente los Periodos de los Grupos. Revisar.',
              color: 'warning'
            })
            return
          }
          if (grupo_id == this.detalles[id].grupo_id && roundNumber(porcentaje, 2) == roundNumber(this.detalles[id].porcentaje, 2)){
            this.$store.dispatch('show_snackbar', {
              text: 'No pueden haber dos Periodos seguidos con el mismo Grupo y Porcentaje. Revisar.',
              color: 'warning'
            })
            return
          }
          fecha_desde = this.detalles[id].fecha_desde
          fecha_hasta = this.detalles[id].fecha_hasta
          grupo_id = this.detalles[id].grupo_id
          porcentaje = this.detalles[id].porcentaje
          if (id == 0 && !cadenaVacia(fecha_hasta)){
            this.$store.dispatch('show_snackbar', {
              text: 'No se dividió correctamente el periodo más reciente. Revisar.',
              color: 'warning'
            })
            return
          }
        }
      }
      // si solo hay 1 periodo se supone fecha hasta vacia hasta esta instancia
      this.dialogConf = true
    },
    async setGrupoComisiones(){
      this.$store.state.loading = true
      let setPeticion = await this.$store.dispatch('financiaciones/set_comisiones_finan', {
        fp_cond_id: this.fp_cond_id,
        grupos: this.detalles
      })
      this.$store.state.loading = false
      if (setPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: setPeticion.msj,
          color: 'error'
        })
        return
      }
      // exito
      this.$store.dispatch('show_snackbar', {
        text: 'Los Periodos fueron guardados con éxito.',
        color: 'success'
      })
      this.activo = false
    }
  },
  watch: {
    activo: function(){
      if (this.activo == true) this.initModal()
      else this.clearModal()
    }
  },
}
</script>

<style>

</style>