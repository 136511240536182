<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
  >
    <v-card>
      <v-card-title>
        Modificar alicuota masivamente
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-6 pb-2">
        <v-row class="d-flex align-center justify-center">
          <v-col cols="6" sm="4" class="py-1">
            <v-form ref="form">
              <v-text-field
                v-model="alicuota"
                label="Nueva alicuota"
                type="number"
                suffix="%"
                :rules="[rules.required]"
                validate-on-blur
                autofocus
                outlined
                dense
              ></v-text-field>
            </v-form>
          </v-col>
          <v-col cols="6" sm="4" class="py-1">
            <FechaPicker
              v-model="desde"
              label="Vig. desde"
            />
          </v-col>
          <v-col cols="6" sm="4" class="py-1">
            <FechaPicker
              v-model="hasta"
              label="Vig. hasta"
              :key="keyHasta"
              :fecha_minima="desde ? moment(parseDate(desde)).format('DD/MM/YYYY') : null"
              :disabled="desde == null"
              :clearable="true"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          class="mr-2"
          color="error"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :texto="`¿Desea modificar la alicuota de las ${financiaciones.length} financiaciones seleccionadas?`"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 *  Modal para editar la alicuota masivamente
 */
import { parseDate } from '../../util/utils'
import BtnConfirmar from '../util/BtnConfirmar'
import FechaPicker from '../util/FechaPicker'
import moment from 'moment'

export default {
  data () {
    return {
      moment: moment,
      parseDate: parseDate,
      keyHasta: 1,
      desde: moment(new Date()).format('DD/MM/YYYY'),
      hasta: null,
      alicuota: null,
      rules: {
        required: value => !!value || 'Campo requerido'
      }
    }
  },
  props: {
    value: Boolean,
    financiaciones: Array
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar,
    FechaPicker
  },
  watch: {
    dialog (val) {
      val || this.limpiar()
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        // valido que haya ingresado fecha desde
        if (!this.desde) {
          return this.$store.dispatch('show_snackbar', {
            text: 'Debe ingresar una vigencia desde',
            color: 'orange'
          })
        }
        this.$store.state.loading = true
        await this.$store.dispatch('financiaciones/cambiar_alicuota_masivo', {
          financiaciones: this.financiaciones.map(f => f.id),
          alicuota: this.alicuota,
          desde: this.desde,
          hasta: this.hasta
        })
          .then((res) => {
            this.dialog = false
            this.$emit('actualizar')
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'success'
            })
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    },
    limpiar () {
      this.alicuota = null
      this.desde = moment(new Date()).format('DD/MM/YYYY')
      this.hasta = null
      this.keyHasta ++
    }
  }
}
</script>